<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      return this.block.fields.LinkInternal?.url
        || this.block.fields.LinkProduct?.url
        || this.block.fields.LinkCategory?.url
        || this.block.fields.LinkExternal?.url
        || undefined;
    },
    isLink() {
      return this.link ? 'router-link' : 'div';
    },
    image() {
      return this.block.fields.Image;
    },
    imageUrl() {
      return this.$toLitiumMediaUrl(this.image.id, {
        maxHeight: Math.ceil(this.image?.height / 50) * 50,
      });
    },
    aspectRatio() {
      if (!this.image?.height && !this.image?.width) {
        return '125%';
      }
      return `${Math.round((this.image?.height / this.image?.width) * 100)}%`;
    },
    textContent() {
      return this.block.fields.BannerText;
    },
    overlays() {
      return this.block.fields.Overlays;
    },
    overlayPositions() {
      return this.overlays?.reduce((res, overlay) => {
        const positions = [];
        const posPhone = overlay.OverlayPositionPhone?.value || 'center';
        const posDesktop = overlay.OverlayPositionDesktop?.value || 'center';

        function addPosition(value) {
          if (value === 'hidden') {
            return;
          }
          if (value && positions.indexOf(value) < 0) {
            positions.push(value);
          }
        }

        addPosition(posPhone);
        addPosition(posDesktop);

        positions.forEach((pos) => {
          if (!res[pos]) {
            res[pos] = [];
          }
          const showOn = [];
          if (pos === posPhone) {
            showOn.push('phone');
          }
          if (pos === posDesktop) {
            showOn.push('desktop');
          }
          res[pos].push({
            showOn,
            overlay,
          });
        });
        return res;
      }, {}) || {};
    },
  },
  methods: {
    getOverlayClassFor(item) {
      const classes = [
        'banner__overlay-item',
        `banner__overlay-item--phone-text-${
          item.overlay.OverlayTextAlignmentPhone?.value || 'center'
        }`,
        `banner__overlay-item--desktop-text-${
          item.overlay.OverlayTextAlignmentDesktop?.value || 'center'
        }`,
        `banner__overlay-item--${this.block.systemId}`,
      ];
      item.showOn.forEach((device) => {
        classes.push(`banner__overlay-item--${device}`);
      });
      return classes;
    },
  },
};
</script>

<template>
  <div class="banner">
    <component
      :is="isLink"
      v-if="image"
      :to="link"
      class="banner__container"
      :style="{paddingTop: aspectRatio}"
    >
      <img class="banner__image" :src="imageUrl">
      <div
        v-for="(items, field) in overlayPositions"
        :key="field"
        :class="[`banner__overlay--${field}`, 'banner__overlay']"
      >
        <div class="banner__overlay-container">
          <div
            v-for="item in items"
            :key="item"
            v-html="item.overlay.OverlayBody"
            :class="getOverlayClassFor(item)"
          ></div>
        </div>
      </div>
    </component>
    <div class="banner__text" v-html="textContent"></div>
  </div>
</template>

<style>
.banner {
  width: 100%;
}

.banner__container {
  width: 100%;
  display: block;
  position: relative;
}

.banner__image {
  display: block;
  object-position: center;
  object-fit: cover;
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  height: 100%;
  width: 100%;
}

.banner__text p{
  margin: 10px 0;
}

.banner__text b{
  margin: 10px 0;
  font-family: var(--font-proxima-nova-bold);
}

.banner__overlay {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: var(--layout-maxWidth);
  display: flex;
  flex-direction: column;
  padding: 20px;
  pointer-events: none;
}

.banner__overlay-item {
  padding: 10px;
  display: none;
  max-height: 100%;
  white-space: normal;
  pointer-events: auto;
}

.banner__overlay-item h1 {
  letter-spacing: -0.3px;
  font-size: 55px;
  margin: 0;
}

.banner__overlay-item h2 {
  font-size: 40px;
  margin: 0;
}

.banner__overlay-item h3 {
  font-size: 30px;
  margin: 0;
}

.banner__overlay-item p {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.9px;
  margin: 8px 0;
}

@media (max-width: 767px) {
  .banner__overlay {
    padding: 10px;
  }

  .banner__overlay-item {
    padding: 5px;
    width: 100%;
  }

  .banner__overlay-item--phone {
    display: block;
  }

  .banner__overlay-item--phone-text-left {
    text-align: left;
  }

  .banner__overlay-item--phone-text-center {
    text-align: center;
  }

  .banner__overlay-item--phone-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .banner__overlay-item--desktop {
    display: block;
  }

  .banner__overlay-item--desktop-text-left {
    text-align: left;
  }

  .banner__overlay-item--desktop-text-center {
    text-align: center;
  }

  .banner__overlay-item--desktop-text-right {
    text-align: right;
  }
}

/* Overlay positions */
.banner__overlay--top {
  align-items: center;
  justify-content: flex-start;
}

.banner__overlay--topleft {
  align-items: flex-start;
  justify-content: flex-start;
}

.banner__overlay--topright {
  align-items: flex-end;
  justify-content: flex-start;
}

.banner__overlay--left {
  align-items: flex-start;
  justify-content: center;
}

.banner__overlay--right {
  align-items: flex-end;
  justify-content: center;
}

.banner__overlay--center {
  align-items: center;
  justify-content: center;
}

.banner__overlay--bottom {
  align-items: center;
  justify-content: flex-end;
}

.banner__overlay--bottomleft {
  align-items: flex-start;
  justify-content: flex-end;
}

.banner__overlay--bottomright {
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
